.main-fields_input {
    padding: 6px 10px;
    border: 1px solid #ccc !important;
    font-size: 12px;
}

.main-fields_label {
    color: #333 !important;
    font-weight: 700;
    font-size: 12px;
}