@import "./global.scss";

.input {
    width: 260px;
    height: 3.1rem;
    border: 1px solid rgba(0, 0, 0, 0.337);
    border-radius: 7px;
    padding: 7px
}

.inputNum {
    // @extend .input;
    width: 260px;
    height: 3.1rem;
    border: 1px solid rgba(0, 0, 0, 0.337);
    border-radius: 7px !important;
    padding: 7px;
}

.inputSearch {
    @extend .input;
    position: relative;
}

.inputSearch2 {
    @extend .input;
    position: relative;
    border: 0px;
    width: 100%;
}

.Dropdown {
    background: white;
    position: absolute;
    padding: 5px;
    height: 100px;
    width: 89%;
    top: 153px;
    overflow-y: scroll;
    z-index: 100;
    --webkit-box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
    --moz-box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);

    ul {
        li {
            font-size: 14px;
            padding: 3px;
            &:hover {
                background: #F8F8F8;
            }
        }
    }
}

select {
    @extend .input;
    text-transform: capitalize !important;

    option {
        text-transform: capitalize;
    }
}

label {
    font-size: 14px;
    font-weight: 600;
    color: $primary !important;
    margin-bottom: 2px;
}

.Btndiv {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        width: 98%;
        background: $primary;
        color: white;
        height: 2.7rem;
        border-radius: 7px;
        font-size: 14px;
        font-weight: 600;

        // &:hover {
        //     box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        // }
    }
}

.Dropdown2 {
    background: white;
    position: absolute;
    padding: 5px;
    height: 100px;
    width: 80%;
    top: 465px;
    overflow-y: scroll;
    z-index: 100;
    --webkit-box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
    --moz-box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);

    &:focus {
        outline: 0px;
        border: 0px;
    }

    ul {
        li {
            font-size: 14px;
            padding: 3px;

            &:hover {
                background: #F8F8F8;
            }
        }
    }
}

.Dropdown3 {
    @extend .Dropdown2;
    top: 180px;
}