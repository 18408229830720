@import "./global.scss";

.backdrop {
    position: fixed;
    z-index: 199999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.562);
}

.container {
    position: fixed;
    z-index: 200000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    overflow-y: auto;

    label {
        color: $gold;
    }

    .innercontainer {
        padding: 20px;
        width: 100%;
        height: 100%;
        position: relative;

        .close {
            position: absolute;
            top: 4px;
            right: 4px;
            width: 20px;
            height: 20px;
            display: grid;
            place-items: center;
            border: none;
            &:hover {
                background: black;
                color: white;
                border-radius: 50%;
            }

            .closebtn{
                font-size: 19px;
                color: black;
            }

        }

        .component {
            max-width: 564px;
            margin: 20px auto;

            h1 {
                margin: 10px 0px;
                text-align: left;
            }
        }
    }
}