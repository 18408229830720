.sidebar-container {
    width: 12vw;
    height: 90vh;
    flex-grow: 0;
    top: 0
}

.navlink-item {
    background-color: #28A48B;
    color: white;
    padding: 1rem 1.2rem;
    width: 15vw;
}

.navlink-item-active {
    color: black;
    padding: 0.8rem 1rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
// .icons{
//     width: 50px !important;
//     height: 50px !important;
// }
.navnames{
    font-size: 15px;
}

