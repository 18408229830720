@import "./global.scss";

.btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-family: $FontFam;

    .previous {
        background: #28a48b;
        color: white;
        padding: 12px;
        border-radius: 10px;
        font-size: 1.1rem;
        font-weight: 600;
        margin: 10px 15px;

        &:disabled {
            background: rgba(223, 214, 214, 0.963);
            color: black
        }
    }

    .next {
        @extend .previous;
    }

    .pageindex {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .select_options {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        // margin-left: auto;

        &:focus {
            outline: 0px;
        }
    }
}