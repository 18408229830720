@import "./global.scss";

.Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: $FontFam;
    gap: 20px;
        height: 842px;
        // overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

    @media print {
        @page {
            margin: 0.3in 1in 0.3in 1in !important;
        }

        @page :footer {
            display: none;
        }

        @page :header {
            display: none;
        }

        body *:not(.Container > *) {
            visibility: hidden;
        }

        #section-to-print,
        #section-to-print * {
            visibility: visible;
            height: auto;
        }

        #section-to-print {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
    }

    .MainBody {
        .Same {
            display: flex;
            height: 2.3rem;
            justify-content: flex-start;

            h5 {
                font-size: 18px;
                font-weight: 600;
                margin: 10px 0px;
                line-height: 22px;
            }

            .phone {
                @extend h5;
                margin: 10px 0px;
                margin-left: 13px !important;
            }
        }

        h5 {
            margin: 10px 0px;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
        }
    }

}

.Container::-webkit-scrollbar {
    display: none;
}