@import "./styles/global.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-circular-progressbar/dist/styles.css';


/* add the code bellow */ 
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

* {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: $FontFam;
}

/* // set before and after border to none */
/* // set margin and padding to 0 */
/* // set box-sizing to border-box */

*::before,
*::after {
  box-sizing: inherit;
}
