$gold:#E8CB69;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

#rightbar{
    background: white;
    padding: 12px;
    padding-bottom: 15px;
    border-radius: 10px;
    color: black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: 'Poppins', sans-serif;
    .actions{
        .prx{
            background: $gold;
        }

        button{
            border-radius: 10px;
        }
    }

    .CardTextDiv{
        h1{
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        .rel{
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    .text-divs{
        p{
            font-size: 1.1rem;
            line-height: 1.4rem;
        }
    }
}

#location{
    width: 25px;
    height: 25px;
    color: black;
    margin-right: 5px;
}