.create-order{
    background: #28a48b;
    color: white;
}

.btn-style{
    background: #0E2A51;
    color: white;
    padding: 7px 9px;
    border-radius: 7px;
}

.my-menu{
    height: 300px;
    overflow-y: scroll;
    width: 200px;
    
}