@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.backbtn {
    border: 2px solid #28a48b;
    border-radius: 15px;
    color: #28a48b;
    width: 100px;
    height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.orderbtn {
    background: #28a48b !important;
    margin: 10px 0px;
}

.itemsheading{
    font-size: 2rem;
    font-weight: 600;
    margin: 15px 0px;
    font-family: 'Poppins', sans-serif;
}