ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

* {
  font-family: 'Rubik', sans-serif;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media print {
        @page {
            margin: 0.3in 1in 0.3in 1in !important;
        }

        @page :footer {
            display: none;
        }

        @page :header {
            display: none;
        }
    }