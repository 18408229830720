.topbar-container {
  width: 100%;
}

.hide-extra-border {
  border: none;
  --webkit-backface-visibility: hidden;
}
.topbar-greetings {
  height: 8vh;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  top: 50px;
  right: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content button {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  padding: 10px;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.logoutbtn{
  font-weight: 600;
  font-size: 15px;
}
