.header_order-number {
    border-radius: 18px;
    background-color: #049079;
    padding: 3px 20px;
    color: #fff;
    font-size: 21px;
    font-weight: 600;
}

.name-logo {
    background-color: #049079;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}